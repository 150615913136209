.about__section-image {
  max-width: 500px;
  max-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid var(--color-gray-100);
  transform: skew(0deg);
  transition: var(--transition);
  object-fit: fill;
}
.about__section-image img {
  height: 100%;
  width: 100%;
}
.about__section-image:hover {
  transform: skew(1deg);
}

.about__section-content h1 {
  margin-bottom: 2rem;
}
.about__section-content p {
  margin-bottom: 1rem;
}

.about__story-container,
.about__mission-container {
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 10%;
}

.about__vision-container {
  display: grid;
  grid-template-columns: 50% 40%;
  gap: 10%;
}

@media screen and (max-width: 1024px) {
  .about__section-image {
    width: 60%;
  }
  .about__section-content h1 {
    margin-bottom: 1.2rem;
  }
  .about__mission-container,
  .about__story-container,
  .about__vision-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .about__vision .about__section-image {
    grid-row: 1;
  }
}

@media screen and (max-width: 600px) {
  .about__section-image {
    width: 80%;
    margin-inline: auto;
  }

  .about__mission-container,
  .about__story-container,
  .about__vision-container {
    gap: 2rem;
  }
}
